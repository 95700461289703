@value sm from './../Breakpoints.module.css';

.masterlegend {
	margin-top: 3rem;
	font-size: 1rem;
	pointer-events: none;
}

.masterlegend > div {
	display: flex;
	align-items: center;
	padding: 2px 0;
}
.masterlegend span {
	color: #999;
}


.palestinianRow .dot {
	background: var(--color2);
}

.dot {
	margin-right: 8px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: var(--color);

}
html[dir=rtl] .dot {
	margin-right: 0;
	margin-left: 8px;
}

.masterlegend.mapLegend {
	position: absolute;
	top: 30px;
	padding: var(--padding);
}

@media (sm){
	.masterlegend.mapLegend {
		display: none;
	}
}