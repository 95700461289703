@value xsm, sm, md from './../Breakpoints.module.css';

.details {
	
	position: absolute;
	right: -80vw;
	left: auto;
	width: 90vw;
	top: 0;
	background: #383838;
	bottom: 0;
	z-index: 10;
	box-shadow: -10px 0 10px rgba(0,0,0,0.3);
	display: flex;
	flex-flow: column nowrap;
	transition: right 150ms;

}

.detailsOpen {
	right: 0vw;
}



html[dir=rtl] .details {
	border-right: 1px solid #444;
}

@media (xsm){
	.details {
		width: 60vw;
		right: -50vw;
	}
	.details.detailsOpen {
		right: 0;
	}
}
@media (sm){
	.details {
		position: relative;
		display: flex;		
		width: auto;
		right: auto;
		background: transparent;
		bottom: auto;
		top: auto;
		box-shadow: none;
		height: 100%;
		overflow: hidden;

	}
	
}

.detailRows {
	overflow: auto;	
	max-height: 100%;
}
.toggle {
	background: #383838;
	width: 32px;
	height: 32px;
	position: absolute;
	top: 50%;
	margin-top: -16px;
	left: -32px;
	padding: 6px;
}

@media (sm){
	.toggle {
		display: none;
	}
}

.casesummary {
	grid-column: span 2;
}

@media (xsm){
	.casesummary {
		grid-column: span 3;
	}
}

.reference {
	grid-column: 1/-1;
}

.detailValues {
	
	
	
}
.detailsTitle {
	padding: 10px var(--padding) 5px var(--padding);
}

.active {
	background: #333;
}



.detail {
	position: relative;
	font-size: 13px;
	grid-gap: 10px;
	padding: 10px 30px 10px var(--padding);
	border-bottom: 1px solid #444;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	cursor: pointer;
}

@media (xsm){
	.detail {
		grid-template-columns: repeat(3, 1fr);
	}
}

.arrow {
	position: absolute;
	right: 10px;
	font-size: 10px;
	color: #999;
	top: 10px;
}

.arrow::before {
	content: '▼';
}

.active .arrow::before {
	content: '▲';
}
[lang="ar"] .arrow,
[lang="he"] .arrow {
	left: 10px;
	right: auto;
}

[lang="ar"] .detail,
[lang="he"] .detail {
	padding: 10px var(--padding) 10px 30px;
}