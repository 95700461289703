@value xsm, sm, md from './../Breakpoints.module.css';

.page {
	background: #171717;
	
}

.pageHero {
	padding: 0;
	
	margin: 0;
}

.pageInner {
	
	padding: 20px var(--padding);
	grid-gap: 40px;
	grid-template-columns: 0 1fr 200px;
}

@media (xsm){
	.pageInner {
		padding: var(--padding);
		padding: calc(var(--padding) * 2);
	}
}

@media (sm){
	.pageInner {
		padding: 0 var(--padding) 100px var(--padding);
		display: grid;
		grid-template-columns: 0 700px 1fr;	
	}	
}

@media (md){
	.pageInner {
		grid-template-columns: 1fr 700px 1fr;
	}
}

@media (sm){
	.pageInner {
/*		max-width: 700px;*/
		margin: 0 auto;
	}
}



.pageText {
	
	font-size: 1.05rem;
	line-height: 1.5;
	letter-spacing: 0.01em;
	
}
@media (sm){
	.pageText {
		font-size: 1.15rem;
	}
}

.imageCaption {
	color: #999;
	font-size: 1rem;
	letter-spacing: 0.01em;
}

.page h1 {
	letter-spacing: 0;
	font-size: 2.1rem;
	line-height: 0.95;
	margin-bottom: 1.5rem;
}

@media (xsm){
	.page h1 {
		font-size: 3rem;
		margin-bottom: 2rem;
	}
}
.logos {
	display: grid;
	grid-template-columns: repeat(2, 100px);
	grid-gap: 40px;
	margin: 40px 0;
}

@media (xsm){
	.logos {
		grid-template-columns: repeat(4, 100px);
		grid-gap: 40px;
	}
}

.infographic {
	margin: 2rem 0;
}

.infographic img {
	display: block;
}

@media (xsm){
	.infographic {
		margin: 3rem 0;
	}	
}

.logos div {


}

.logos img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.hero {
	height: auto;
	margin: 0;
	position: relative;
	overflow: hidden;
}

@media (xsm){
	.hero {
		max-height: 700px;
		min-height: 450px;
		height: 45vh;
	}
}



@media (sm){
	.hero {
		margin: 0 auto 2rem auto;
		min-height: 550px;
	}
}

.heroimage img,
.heroblur img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}



.heroimage {
	margin: 0 auto;
	height: 100%;
	position: relative;	
	max-width: 1250px;
	z-index: 1;
}

.heroblur {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

.heroblur img {
	filter: blur(10px);
	transform: scale(1.2);
}

