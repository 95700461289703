@value xsm, sm, md from './Breakpoints.module.css';

body {
	background: #222;
	color: #fff;
	margin: 0;
	
	line-height: 1.3;
	--padding: 15px;
}



@media (xsm){
	body {
		--padding: 20px;		
	}
}

.headerOpen {
	background: #222;
}

.headerOpen .nav {
	display: block;
}



body[data-page="map"]{
	overflow: hidden;
	height: 100vh;
}



.lastUpdated {
	right: 15vw;
	color: #999;
	font-size: 1rem;
	position: fixed;
	bottom: 7px;
	z-index: 1000;
}

@media (xsm){
	.lastUpdated {
		position: absolute;
		top: 11px;
		bottom: auto;
		right: 0;
	}	
}

body[data-page="map"] header {
	position: relative;
}

.mobileToggle {
	position: absolute;
	right: 0;
	top: 0;
	padding: var(--padding);
	color: #FFF;
	text-transform: uppercase;
}

html[dir=rtl] .mobileToggle {
	right: auto;
	left: 0;
}


@media (sm){
	.mobileToggle {
		display: none;
	}
}


.filters {
	display: grid;
	padding: 10px 0 0 0;
	grid-gap: 10px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (sm){
	.filters {
		
		grid-template-columns: repeat(4, minmax(0, 1fr)) 1.5fr;
	}
}

@media (sm){
	.filter {
		padding: 13px 0 0 0;
	}
}

.search {
	display: none;
	position: relative;
	
	flex-flow: column nowrap;
	justify-content: flex-end;
}

@media (sm){
	.search {
		display: flex;	
	}
	
}

.search input {
	background: #292929;
	border: none;
	padding: 3px 3px 3px 30px;
	font-family: inherit;
	font-size: inherit;
	display: block;
	width: 100%;
	letter-spacing: inherit;
	color: #FFF;
}

.search input::placeholder {
	color: #777;
}

.searchInput {
	position: relative;
}

.searchIcon {
	position: absolute;
	letter-spacing: 0;
	top: 6px;
	left: 8px;
	letter-spacing: 0;
	width: 12px;
}

.searchIcon img {
	width: 100%;
	height: auto;
	display: block;
}

.results {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 10px 0;
	position: relative;
}



@media (sm){
	.results {
		display: block;
		text-align: center;
	}
}	

.filterToggle {
	display: flex;
	align-items: center;
}

.filterToggle img {
	width: 18px;
	opacity: 0.8;
	margin-right: 5px;
}

@media (sm){
	.filterToggle {
		display: none;
	}	
}


.results button {
	background: transparent;
	font-size: inherit;
	font-family: inherit;
	border: none;
	padding: none;
	color: #999;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	text-decoration-color: #999;
	padding-left: 10px;
	color: inherit;
}

main {
	border-right: 1px solid #444;
/*	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));*/
}

.timeline {
	padding: 10px var(--padding) var(--padding) var(--padding);
	border-right: 1px solid #444;
	display: none;
	overflow: auto;
}

@media (sm){
	.timeline {

		display: block;
	}
}
.container {
	
	flex-grow: 1;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-flow: column nowrap;
}
@media (sm){
	.container {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 100px 1fr;
	}
	

}

@media (md){
	.container {
		grid-template-rows: 95px 1fr;
	}
}

.columns {
	flex-grow: 1;	
	overflow: hidden;
	position: relative;
}
@media (sm){
	.columns {
		grid-template-columns: 1fr 1fr 1fr;
		display: grid;
		
	}
	

}


.filterContainer {
	grid-column: span 3;
	background: #000;
	padding: 0 var(--padding) 0 var(--padding);

	border-bottom: 1px solid #444;
}

@media (sm){
	.filterContainer {
		background: #171717;
	}
}

.filterContainerInner {
	/*max-width: 900px;
	margin-left: auto;*/
	display: none;
	
}

.filterOpen .filterContainerInner {
	display: block;
}

.filterOpen + .results {
	padding-top: 20px;
}

@media (sm){
	.filterContainerInner {
		background: #171717;
		grid-gap: calc(var(--padding) * 1);
		display: grid;
		grid-template-columns: 2fr 1fr;
		align-items: flex-start;
	}
}


.navInner {
	display: block;
	grid-gap: 10px;
	justify-content: flex-end;	
	border-top: 1px solid #444;
	margin-top: 20px;
}

@media (sm){
	.navInner {
		margin-top: 0;
		border-top: none;
		display: flex;
	}
}



.nav {
	flex-shrink: 0;
	display: none;
}
.navInner a {
	color: #FFF;
	text-decoration: none;
	padding: 6px 0 5px 0;
	border-bottom: 1px solid #444;
	display: block;
	font-size: 16px;
}

@media (sm){
	.navInner a {
		font-size: 1rem;
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 2px;
		text-decoration-color: inherit;
		padding: 0;
		border: none;
	}
}

.nav .activeLink {
	color: var(--color);
}

@media (sm){
	.nav {
		display: block;
	}
}

[lang="ar"] .languageList,
[lang="he"] .languageList {
	justify-content: flex-start;
}

header {
	position: relative;
	color: var(--color);
	position: sticky;
	top: 0;
	background: #171717;
	z-index: 100;
}



main {
	height: 100%;
}

.languageList {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
/*	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: flex-end;*/

	/*position: absolute;
	right: var(--padding);*/
	z-index: 1;
/*	padding: 10px;*/
/*	background: #171717;*/
/*	top: var(--padding);*/
/*	width: 216px;*/
	direction: ltr;

}
@media (sm){
	.languageList {
		margin-bottom: 0;
	}
	
}

.pageContainer {
	display: flex;
	flex-flow: column nowrap;
}
.languages {
	/*position: absolute;
	left: 50%;
	bottom: -14px;
	width: 180px;
	margin-left: -90px;*/
	z-index: 10;
	margin: 15px 0 0 0;
	
}

.logoSubtitle {
	display: block;
	grid-template-columns: 100px 1fr;
	grid-gap: calc(var(--padding) * 2);

}
@media (xsm){
	.logoSubtitle {
		display: grid;
		grid-template-columns: 120px 1fr;
		grid-gap: var(--padding);	
	}
}

@media (sm){
	.logoSubtitle {
		grid-gap: calc(var(--padding) * 2);	
		grid-template-columns: 180px 1fr;
	}
}

.subtitle {
	max-width: 550px;
}

@media (sm){
	.subtitle {
		max-width: 800px;
	}
}

.subtitle a {
	text-decoration: none;
}

.subtitle:hover {
	filter: brightness(1.2);
}



body[data-page="map"] .headerInner {


}

.headerInner {
	border-bottom: 1px solid #444;
	padding: 15px var(--padding) 12px var(--padding);
/*	display: grid;
	grid-template-columns: 1fr 1fr;*/
	grid-gap: var(--padding);
	display: block;
	justify-content: space-between;
}

@media (sm){
	.headerInner {
		display: flex;
		padding: 15px var(--padding) 15px var(--padding);
	}
}

.languageList a {
	font-size: 14px;
	padding: 2px 6px 2px 6px;
	border-radius: 2px;

	text-decoration: none;
	margin: 0 2px;
	color: #FFF;
	
	background: #606060;
	display: block;
	height: auto;

	color: #ddd;
	text-align: center;
}

.languageList .activeLang a {
	background: var(--color);
	color: #000;
}

[data-lang="ar"] .logo,
[data-lang="he"] .logo {
	padding-right: 0;
	padding-left: calc(var(--padding) * 2);
}

.logo {
	max-width: 120px;
}

.logo a:hover {
	opacity: 0.8;
}

@media (sm){
	.logo {
		max-width: none;
		margin-top: 4px;
	}
}

.titleLanguages {
	color: var(--color);
	margin-bottom: var(--padding);
}
.subtitle {
	font-size: 16px;
	line-height: 1.1;
	letter-spacing: -0.01em;
	font-weight: 500;
}
@media (sm){ 
	.subtitle {
		font-size: 1.6vw;
	}
}