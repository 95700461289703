@value lg: min-width: 1450px;
@value md: min-width: 1310px;
@value sm: min-width: 1024px;
@value xsm: min-width: 767px;

html {
	font-size: 13px;
	--padding: 15px;
}

@media (xsm){
	html {
		font-size: 14px;
	}
	
}

@media (md) {
	html {
		--padding: 20px;
	}
}