.timelineReference div {
	border-left: 1px solid #333;
	height: 100%;

}


.timelineReference div:nth-child(odd) {
/*	background-color: #233;*/

}

.timelineContainer {
	position: relative;

}
.bar {
	height: 1px;
	position: relative;
}

.timeline {
	position: relative;
}

.legend {
	display: flex;
	justify-content: space-between;
	color: #999;
	margin-top: 5px;
	direction: ltr;
}

.inactive .barInner {
	opacity: 0.25;
}
.barActive .barInner {
	background-color: #dfff00;
}


.bar:hover .barInner {
	background-color: #ffa000;
}

.timelineReference {
	position: absolute;
	border: 1px solid #333;
	border-left: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;

}

.timelineTitle {
	margin-bottom: 10px;
}

.timeline {
	position: relative;

	overflow: hidden;
}

.barInner {
	position: absolute;
	height: 100%;
	background-color: var(--color);
}

.barInner.palestinianRow {
	background-color: var(--color2);
}

.invalid {
	
	/*opacity: 0;
	display: none;*/
}



