@value xsm, md from './Breakpoints.module.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;500&family=Noto+Sans+Hebrew:wght@500&display=swap');

@font-face {
	font-family: 'Haas-Web';
	font-weight: 400;
	src: url('./fonts/2E39A8_3_0.woff') format('woff');
}

@font-face {
	font-family: 'Haas-Web';
	font-weight: 500;
	src: url('./fonts/NeueHaasUnicaPro-Medium.woff2') format('woff');
}

html {
	font-size: 14px;
}
@media (md){
	html {
		font-size: 15px;
	}
}

/*@font-face {
	font-family: 'NeueHaasAgate';
	font-weight: 400;
	src: url('./fonts/NeueHaasGroteskAgate-Regular-Web.woff2') format('woff');
}

@font-face {
	font-family: 'NeueHaasGrotDisp';
	font-weight: 500;
	src: url('./fonts/NeueHaasGrotDisp-65Medium-Trial.otf') format('opentype');
}
*/

body {
	font-family: 'Haas-Web', 'Noto Sans Arabic', 'Noto Sans Hebrew', sans-serif;
	letter-spacing: 0.01em;
	
}

h4 {
	margin: 0 0 5px 0;
	font-weight: 400;
	color: #999;
	font-size: 1rem;
}
h1 {
	margin: 0;
	font-weight: 500;
	font-size: 1.8rem;
	font-family: 'Haas-Web', 'Noto Sans Arabic', 'Noto Sans Hebrew', sans-serif;
	line-height: 1.1;
	letter-spacing: -0.02em;
	-webkit-font-smoothing: antialiased;
}

label {
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: 0.07em;
	color: #999;
	line-height: 1.3;
	display: block;
	margin-bottom: 2px;
/*	//font-family: 'NeueHaasAgateMono';*/

}

h3 {
	font-size: 1.15rem;
	line-height: 1.5;
	letter-spacing: 0.01em;
	font-weight: normal;
	color: var(--color);
/*	margin: 0;
	font-weight: 500;
	
	font-family: 'Haas-Web', 'Noto Sans Arabic', 'Noto Sans Hebrew', sans-serif;
	line-height: 1.1;
	letter-spacing: -0.02em;
	-webkit-font-smoothing: antialiased;*/
}

ul {
	padding-left: 0.8em;
}

li {
	padding-left: 0.4em;
}