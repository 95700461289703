.map {
	width: 100%;
	height: 100%;
	position: relative;
}

.date {
	position: absolute;
	
	z-index: 1;
	top: 10px;
	left: var(--padding);
	
	
}


.date h4 {
	margin-bottom: 0;
}
.dateValue {
	color: var(--color);
	font-size: 1.6rem;
	font-weight: 500;
	letter-spacing: -0.02em;
	direction: ltr;
}

