.filter {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;


}

.filter select {
	width: 100%;
	background: #292929;
	border: none;
	color: #777;
	font-family: inherit;
	font-size: inherit;
	padding: 3px;
	letter-spacing: 0.02em;
	line-height: 1;


}

.filter select::placeholder {

}

.filter button {
	background: var(--color);

	border: none;
	bottom: 2px;
	right: 2px;
	position: absolute;
	font-size: 1rem;
	height: 20px;
	width: 24px;
}

.active select {
	background: var(--color);
	color: #000;
}