

:root {
	
	--color: #ff5722;
	--color2: #9d22ff;
}

html, body {
	height: 100%;
}

#root {
	height: 100vh;
	overflow-x: hidden;
}

#root > div {
	height: 100%;
}

* {
	box-sizing: border-box;
}

.slider {
	
	
	min-height: 22px;
}

#root {
	
	
}

a {
	color: inherit;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	text-decoration-color: inherit;
}

.mapboxgl-ctrl-attrib {
	display: none !important;
}
.mapboxgl-ctrl-logo {
	opacity: 0.5;
}