


.play {
	/*position: absolute;
	top: 10px;
	left: 10px;*/
	z-index: 1;

}

.sliderFlexContainer {
	display: flex;
	justify-content: flex-end;
	flex-flow: column nowrap;
	height: 100%;
}

.sliderContainer {
	position: relative;
	padding: 7px 0 0 0;
	display: grid;
	grid-template-columns: 65px 1fr;
	align-items: flex-end;
	grid-gap: 10px;

}
.play button {
	background-color: var(--color);
	appearance: none;
	border: none;
	font-size: inherit;
	font-size: 14px;
	padding: 2px 6px 1px 6px;
	border-radius: 2px;
	line-height: inherit;
	font-family: inherit;
	width: 100%;
}
.play img {
	width: 8px;
	height: auto;
	position: relative;
	top: 1px;
}



.legend {
	width: 100%;
	display: flex;
	justify-content: space-between;
	direction: ltr;
	/*position: absolute;
	top: 20px;*/
}

.legend .thumb {
	background-color: transparent;
	color: #999;
}


.thumb {
	background-color: var(--color);
	color: #000;
	cursor: pointer;
	font-size: 14px;
	height: 22px;
	padding: 2px 6px 1px 6px;
	border-radius: 2px;
}
.thumb:hover {

}


.track {
	height: 22px;
	background-color: #292929;
}
.track:nth-child(2){
	background-color: #ff572252;
}